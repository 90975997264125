.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom checkbox styles */
.custom-checkbox {
  @apply relative flex items-center flex-grow;
}

.custom-checkbox input[type="checkbox"] {
  @apply hidden;
}

.custom-checkbox label {
  @apply pl-7 cursor-pointer text-gray-700 hover:text-gray-900 transition-colors select-none;
  @apply flex items-center min-h-[1.5rem] w-full;
}

.custom-checkbox label::before {
  content: '';
  @apply absolute left-0 w-5 h-5 border border-gray-300 rounded-md;
  @apply transition-all duration-200;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
  @apply bg-gray-200 border-gray-300;
}

.custom-checkbox label::after {
  content: '';
  @apply absolute left-[6px] top-[7px] w-2 h-4 border-gray-600;
  @apply border-r-2 border-b-2 opacity-0 transform rotate-45 transition-all duration-200;
}

.custom-checkbox input[type="checkbox"]:checked + label::after {
  @apply opacity-100;
}

.custom-checkbox:hover label::before {
  @apply border-gray-400 bg-gray-50;
}
